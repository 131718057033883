
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { ethers } from "ethers";
import { ChainIds, switchNetwork } from "../utils/MetaMask";

const NounsVille = {
  wabi: require("../abis/NounsvilleToken.json"), // wrapped abi
  address: "0x163B3906884df904EFF51bf21E7Ee3D3f87098D3"
};
const MessageBox = {
  wabi: require("../abis/MessageBox.json"), // wrapped abi
  address: "0x2468b1c59D907852c4645547e45537075C2fa933"
};

// no topics means any events
const filter = {
  address: NounsVille.address,
  /*
  topics: [
    utils.id("NounBought(uint256,address)")
  ]
  */
};

const shorten = (address: string) => {
  return address.substring(0,6) + "..." + address.substring(38);
};

export default defineComponent({
  name: "HomePage",
  components: {
  },
  setup() {
    const expectedNetwork = ChainIds.RinkebyTestNet;
    const store = useStore();
    const tokenBalance = ref(0);
    const selected = ref("");
    const message = ref("");
    const justMinted = ref(false);
    const users = ref([] as Array<object>);
    const messages = ref([] as Array<object>);
    const holder = computed(() => {
      if (store.state.account && store.state.chainId == expectedNetwork) {
        const provider = new ethers.providers.Web3Provider(store.state.ethereum);
        const signer = provider.getSigner();
        const nounsville = new ethers.Contract(NounsVille.address, NounsVille.wabi.abi, signer);
        const messagebox = new ethers.Contract(MessageBox.address, MessageBox.wabi.abi, signer);
        provider.on(filter, (log, event) => {
          console.log("**** got event", log, event);
          justMinted.value = false;
          fetchBalance();
        });
        return { nounsville, provider, signer, messagebox };
      }
      return null;
    });
    const fetchBalance = async () => {
      if (!holder.value) return;
      const count = await holder.value.nounsville.functions.balanceOf(store.state.account);
      //console.log("**** count", count[0].toNumber());
      tokenBalance.value = count[0].toNumber();
    };
    const fetchMessages = async () => {
      if (!holder.value) return;
      const messagebox = holder.value.messagebox;      
      const result = await messagebox.functions.count();
      console.log("***** message count", result[0].toNumber());
      const itemCount = result[0].toNumber();
      const promises = [...Array(itemCount).keys()].map((index) => {
        return messagebox.functions.get(index);
      });
      const items = (await Promise.all(promises)).map((result) => {
        const value = result[0];
        return { sender: value[0], senderName: shorten(value[0]), text: value[2] }
      });
      console.log("***** messages", items);
      messages.value = items;
    };
    const fetchUsers = async () => {
      if (!holder.value) return;
      const nounsville = holder.value.nounsville;      
      const result = await nounsville.functions.totalSupply();
      //console.log("***** totalSupply", result);
      const itemCount = result[0].toNumber();
      const promises = [...Array(itemCount).keys()].map((index) => {
        return nounsville.functions.ownerOf(index);
      });
      const owners = (await Promise.all(promises)).map((result) => {
        const address = result[0].toLowerCase();
        return { address, name:shorten(address) };
      }).filter((user) => {return user.address !== '0x000000000000000000000000000000000000dead'});
      //console.log("***** users", owners);
      users.value = owners;
    };
    const mint = async () => {
      if (!holder.value) return;
      await holder.value.nounsville.functions.mint();
      justMinted.value = true;
    };
    const tokenGate = computed(()=>{
      if (!store.state.account) {
        return "noAccount"
      }
      if (store.state.chainId != expectedNetwork) {
        return "invalidNetwork"
      }
      fetchBalance();
      fetchUsers();
      fetchMessages();
      return "valid";      
    });
    const switchToValidNetwork = async () => {
      console.log(expectedNetwork);
      await switchNetwork(expectedNetwork);
    }
    const selectUser = (address:string) => {
      selected.value = address;
    };
    const sendMessage = async () => {
      if (!holder.value) return;
      const messagebox = holder.value.messagebox;    
      console.log("calling send", selected.value, message.value);
      const result = await messagebox.functions.send(selected.value, message.value); /*, {
        gasLimit: 100000
      });  */
      console.log("just send", result);
      selected.value = "";
      message.value = "";
    };
    const account = computed(()=>{
      if (store.state.account) {
        return store.state.account.toLowerCase();
      }
      return null;
    });    
    return {
      account,
      users,
      selected, selectUser,
      message, sendMessage, messages,
      mint, justMinted,
      tokenGate,
      tokenBalance,
      switchToValidNetwork
    }
  }
});
