
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { requestAccount } from "../utils/MetaMask";

export default defineComponent({
  setup() {
    const store = useStore();
    const account = computed(() => store.state.account);
    const isSignedIn = computed(() => store.getters.isSignedIn);
    const isBusy = ref("");
    const connect = async () => {
      isBusy.value = "Connecting Metamask...";
      try {
        await requestAccount(); // ethereum.on('accountsChanged') in App.vue will handle the result
      } catch(e) {
        console.log(e);
      }
      isBusy.value = "";
      console.log("*****", store.state.account);
      // signIn();
    };
    const hasMetaMask = computed(()=>{
      return store.getters.hasMetaMask;
    });

    return {
      hasMetaMask,
      account,
      isSignedIn,
      isBusy,
      connect,
    };
  },
});
