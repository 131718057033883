import languages from "./languages";

const lang = {
  menu: {
    connect: "Connect",
    connected: "Connected",
  },
  message: {
    hello: "hello world",
  },
  languages,
};

export default lang;
